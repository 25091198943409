@import "./controller/wsbController.scss";
@import "./login/wsbLogin.scss";

#wsb {
  * { 
    color: white;
    font-family: 'Open Sans';
  }
  
  .gradientBg {
    border-radius: 12px;
    background: linear-gradient(to bottom, #45723a, #305f8b, #7b8952);
    padding: 1px;
  
    .blackBg {
      background: black;
      background: linear-gradient(
        180deg,
        #181c27 0%,
        rgba(24, 28, 39, 0.95) 100%
      );
      backdrop-filter: blur(11px);
      border-radius: 12px;
    }
  }
  
  .wsbName {
    * {font-family: "Asap Condensed";}
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: white;
    background-color: transparent;
  
    .wall {
      font-weight: 400;
    }
  
    .street {
      font-weight: 600;
      background: linear-gradient(174deg, #27e050 0%, #27e0bf 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .blast {
      font-weight: 600;
    }
  }
}