.barDiceController {
  @extend .pageFrame !optional;
  .green {color: #27E050;}
}

nav {
  width: 100%;
  * {text-wrap: nowrap;}

  .barDiceName {
    font-family: "Days One";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #e8c305;
    background-color: transparent;
    font-size: clamp(11px, 3vw, 14px);;
  }

  .barDiceNavWallet {
    background: linear-gradient(124deg, #27E050 29.78%, #27E0BF 81.48%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: clamp(11px, 3vw, 15px);
    text-align: center;
    // margin: 0 2vw;
    padding: 2px 5px;
    border: 1px solid #42404B;
    border-radius: 1vh;
  }

  .username { 
    font-family: "Days One";
    font-size: clamp(10px, 3vw, 12px);
    text-transform: capitalize;
    max-width: 30%;
    text-overflow: clip;
  }

  .colCenter { 
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .middle { margin: 0 auto; }
  .right { margin-left: 0.5em; }
  .spaceBetween { justify-content: space-between;}

  .jackpotText {
    position: relative;
    font-family: "Open Sans";
    >div {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 10px;
      text-align: center;
    }
  }
}

.barDiceScreen {
  height: 30vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .chartText {
    text-align: center;
    font-family: 'Days One';
    font-size: clamp(11px, 12vw, 60px) !important;
  }

  &.starBg {
    background-color: #000;
    background-image: url('../assets/starryBg.png');
    background-size: contain;
    background-clip: border-box;
    border-radius: 2vh;
  }
  .smallGap { margin-top: 1vh; }
  .slightlySmallerText { font-size: clamp(16px, 8.5vw, 37.5px); }

  &-lower {
    @extend .barDiceScreen;
    font-size: clamp(11px, 8.5vw, 60px);
    height: 37vh;
  }
}

.payout {color: #27E050;}

.barDiceButtons {
  display: flex; 
  flex-direction: column;
  text-align: center;
  font-family: 'Days One';
  font-size: 1.1em;
  font-weight: 600;

  &.disabled {
    filter: blur(1.5px);
  }
  
  .adjusters {
    height: 37vh;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    >* {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 1vh;
    }
    >:nth-child(1) {margin-top: 3%;}
    >:last-child {margin-bottom: 3%;}
  }

  .square.quickBets {
    display: flex; 
    flex-direction: column;
  }

  .square {
    width: 47%;
    >.blackBg {
      height: 10vh; 
      display: flex; 
      flex-direction: column;
      justify-content: space-evenly;
      padding: 5px 0;
      .limit {
        position: relative;
        display: flex;
        justify-content: center;
        &-left { 
          position: absolute;
          left: 3%;
          top: -2px;
        }
        &-right {
          position: absolute;
          right: 4%;
          top: -2px;
        }
      }
      .betAmount {
        color: #e8c305;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .quickBets {
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    .row {height: 47%;}
    .quickBet {
      width: 47%;
      background: linear-gradient(174deg, #E8C305 85%, #E8C305D9 100%);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 0.9em;
    }
  }
  
  .fill {
    width: 100%;
    display: flex; 
    flex-direction: column;
  }

  .diceGuess {
    display: flex;
    flex-grow: 0;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: center;
    align-content: center;
  }

  .guessInput {
    display: flex;
    background: transparent;
    border: none;
    color: white;
    &::placeholder { color: #888888; }
    &:focus { outline: none; }
    max-width: 70%;
    font-size: 1em;
    font-weight: 700;
    font-family: 'Days One';
    text-align: center;
  }
  
  .instructions {
    width: 100%;
    font-size: clamp(11px, 3vw, 19px);
    font-weight: lighter;
    margin: 0 auto;
    margin-top: 1%;
  }

  .row.fields>span {
    font-size: 1px;
  }

  @mixin thumb {
    border: 1px solid #000000;
    height: clamp(12px, 5vw, 18.75px);
    width: clamp(12px, 5vw, 18.75px);
    border-radius: clamp(12px, 5vw, 18.75px);
    background: #E8C305;
    cursor: pointer;
  }

  @mixin track {
    width: clamp(12px, 100%, 375px);
    height: clamp(12px, 2.5vw, 9.375px);
    cursor: pointer;
    background: linear-gradient(to right, #e8c305 0%, #e8c305 var(--slider), #C5C5C5 var(--slider), #C5C5C5 100%);
    border-radius: clamp(12px, 5vw, 18.75px);
  }

  .slider {
    width: clamp(12px, 100%, 375px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: none;
    input[type=range] {
      // Erase default styling
      -webkit-appearance: none;
      background: transparent;
      &::-webkit-slider-thumb {-webkit-appearance: none;}
      &:focus {outline: none;}
      &::-ms-track {
        width: clamp(12px, 100%, 375px);
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }

      // Style thumb
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin-top: clamp(-4px, -1.25vw, 0px);
        @include thumb();
      }
      &::-moz-range-thumb {@include thumb();}
      &::-ms-thumb {@include thumb();}

      // Style track
      &::-webkit-slider-runnable-track {@include track();}
      &:focus::-webkit-slider-runnable-track {
        background: #E8C305;
      }
      &::-moz-range-track {@include track();}
      &::-ms-track {@include track();}
      &::-ms-fill-lower {
        background: #E8C305;
        border-radius: clamp(12px, 5vw, 18.75px);
      }
      &::-ms-fill-upper {
        background: #C5C5C5;
        border-radius: clamp(12px, 5vw, 18.75px);
      }
      &:focus::-ms-fill-lower {background: #E8C305;}
      &:focus::-ms-fill-upper {background: #C5C5C5;}
    }
  }
}

.position {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .button {
    width: 100%;
    font-family: "Days One";
    text-align: center;
    border-radius: 6px;
    border: 1px solid #42404B;
    box-shadow: 12px 16px 22px 0px rgba(22, 25, 97, 0.33);
    font-size: 16px;
    font-weight: 700;
    padding: 5% 0;
    &.confirm {
      background: linear-gradient(172deg, #9AE18A -10.33%, #3AE512 44.59%);
    }
    &.cancel {
      background: linear-gradient(125deg, #E25B5B 11.04%, #CD3B3B 70.92%);
    }
    &.disabled {
      background: grey;
    }
  }
}

.toastWrapper {
  // *{z-index: 5000;}
  border-radius: 12px;
  border: 2px solid #42404B;
  background: linear-gradient(257deg, #181C27 40.76%, #181C27 77.76%);
  margin-top: 10vh;


  font-family: "Days One";
  font-size: 10vw;
  color: #D5B409;
  text-align: center;

}

.jogoMarquee {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: hidden;
  font-size: 12px;
  font-family: "Days One";
  font-weight: 600;
  border: 1px solid #42404B;
  border-radius: 6px;
  * {margin: 0.75vw;}
}

.jackpotController {
  position: absolute;
  bottom: 5px;
  right: 10px;
  display: flex;
  flex-direction: column;

  text-align: center;
  text-shadow: -1px 0 #A9140B, 0 1px #A9140B, 1px 0 #A9140B, 0 -1px #A9140B;

  // .jackpotTotal {
  //   width: clamp(50px, 40vw, 200px);
  //   height: clamp(20px, 12vw, 60px);
  //   background-image: url("../assets/jackpotTotal.png");
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   font-size: clamp(0.75em, 3vw, 16px);
  // }

  .ticketWrapper {
    display: flex;
    justify-content: right;
  }

  .jackpotTickets {
    width: 50px;
    height: 20px;
    background-image: url("../assets/jackpotTicket.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    line-height: 130%;
  }

}

