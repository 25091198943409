.wsbController {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  nav {
    color: white;
    font-family: 'Open Sans';
  
    .wallet {
      background: linear-gradient(124deg, #27E050 29.78%, #27E0BF 81.48%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 1.1em;
      text-align: center;
      margin: 0 2vw;
    }
  
    .username { font-size: 0.8em; }
    .col.center { justify-content: center; }
    .middle { margin: 0 auto; }
    .right { margin-left: auto; }
  }

  .bottom {
    justify-self: flex-end;
  }
}