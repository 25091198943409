#backOfficePortal {

  font-family: "Open Sans";
  @extend .pageFrame !optional;

  nav {
    width: clamp(90%, 90%, 450px);
    margin: 0 auto;
    font-size: clamp(12px, 4vw, 20px);
    font-weight: 600;
    >img {max-width: 100px;}
  }

  .mr-1vw { margin-right: 1vw;}

  .stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    background-color: #1A1D25;
    border-radius: 7px;
    >* {
      width: 30%;
      text-align: center;
      font-weight: 600;
      font-size: clamp(14px, 5vw, 20px);
      border-right: 1px solid #2C3042;
    }
    >:last-child {border-right: none;}
  }

  .eventStart {
    color: #C5C5C5;
    font-size: clamp(11px, 3vw, 14px);
    text-align: right;
    width: 95%;
    font-style: italic;
  }

  .categoryFilter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: clamp(14px, 5vw, 20px);
    font-weight: 600;
    
    .active {
      background-color: #6CDD64;
      border-radius: 8px;
    }

    >* {padding: 0 1.5vw;}
  }

  .searchBar {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    padding: 1vw 0;
    border-radius: 7px;
    border: 1px solid #42404B;
    background: #1A1D25;

    .searchIcon {
      padding-left: 2vw;
      padding-right: 0;
    }

    input {
      @include invisibleInput();
      width: 85%;
      &::placeholder {
        color: #C5C5C5;
        font-weight: 300;
      }
    }
  }

  .requestTable {
    display: flex;
    flex-direction: column;
    width: 97.5%;
    margin: 0 auto;
    overflow-y: hidden;
  }

  .requestHeaders {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    color: #C5C5C5;
    font-size: clamp(12px, 3.5vw, 16px);
    border-bottom: 1px solid #42404B;
  }

  .reqCol {
    &1, &3 { text-align: center; width: 25%; }
    &2 { text-align: center; width: 50%; }
  }

  .requestList {
    height: 70vh;
    font-size: clamp(14px, 5vw, 20px);
    font-weight: 400;
    overflow-y: scroll;
  }

  .requestLine {
    margin-top: 1vh;
    padding: 1vh 0;
    border-radius: 7px;
    line-height: 200%;
    background-color: #1A1D25;
    .reqCol1 {border-right: 1px solid #42404B}
    .reqCol3 {border-left: 1px solid #42404B}

    &-deposit {
      @extend .requestLine;
      border: 1px solid #63C95D;
    }

    &-withdraw {
      @extend .requestLine;
      border: 1px solid #42404B;
    }
  }
}

#requestModal {
  height: 35vh;
  max-height: 200px;
  max-width: 450px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  z-index: -99;

  font-family: 'Open Sans';
  background-color: black;
  border: 1px solid #2C3042;
  border-radius: 12px;
  padding: 5vw;

  .cancel {
    font-size: clamp(14px, 5vw, 20px);
    font-weight: 600;
  }

  .requestAmount {
    font-size: clamp(18px, 7vw, 24px);
    font-weight: 600;
    margin: 0 auto;
  }

  .codePart {
    font-size: clamp(20px, 10vw, 36px);
    font-weight: 700;
    border: 2px solid #42404B;
    border-radius: 12px;
    width: 15vw;
    max-width: 60px;
    display: flex;
    justify-content: center;
  }

  .resolveRequest {
    font-size: clamp(12px, 5vw, 14px);
    font-weight: 600;
    border-radius: 4px;
    width: 48%;
    max-width: 320px;
    display: flex;
    justify-content: center;
    padding: clamp(10px, 5%, 50px) 0;

    &-green {
      @extend .resolveRequest;
      background: linear-gradient(107deg, #27E050 -6.52%, rgba(39, 224, 191, 0.20) 142.5%);
    }
    &-red {
      @extend .resolveRequest;
      background: linear-gradient(102deg, #E91E63 24.99%, rgba(233, 30, 99, 0.20) 191.94%);
    }
  }
}