.wsbLogin {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Open Sans';

  .topHalf {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .back {
    margin-top: 5%;
    width: 95%;
    font-size: 22px;
    font-weight: 700;
    
    .visible {
      background: linear-gradient(174deg, #27e050 0%, #27e0bf 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .invisible {
      @extend .visible;
      background: transparent;
    }
  }

  .wsbLogo {
    margin-top: 5%;
  }

  .wsbName {
    @extend .wsbName;
    font-size: 26px;
    margin-top: 3%;
  }

  .title {
    font-family: 'Asap Condensed';
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    background: linear-gradient(111deg, #27E050 18.51%, #53FFE0 77.49%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .title-wrapper { margin-bottom: 4%; }

  .phoneEntry {
    position: absolute;
    top: 40%;
    width: 90%;
    .phoneLine {
      display: flex;
      flex-direction: row;
      .phoneIcon { padding: 3% 4%; }
      input {
        width: 100%;
        font-family: 'Open Sans';
        font-size: 0.1em;
        font-weight: 200;
        color: white;
      }
    }
  }

  .codeEntry {
    position: absolute;
    top: 35%;
    width: 90%;
    .code {
      display: flex;
      justify-content: space-between;
      margin-top: 3%;
      .gradientBg {
        width: 20%;
      }
      .blackBg {
        width: 100%;
        display: flex;
      }
      input {
        @extend .greyText !optional;
        width: 50%;
        text-align: center;
        font-size: 3em;
        &::placeholder {
          font-size: 21px;
          line-height: 150%;
        }
        margin: 0 auto;
        padding-bottom: 10%;
        padding-top: 20%;
      }
    }
  }

  .greyText {
    color: #EEF1FF;
    font-family: 'Open Sans';
    font-weight: 400;
    text-align: center;
  }

  button {
    width: 90%;
    margin: 5% auto;
    padding: 4% 0;

    &.submit {
      color: #161825;
      font-family: 'Asap Condensed';
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      border-radius: 12px;
      background: linear-gradient(124deg, #27E050 29.78%, #27E0BF 81.48%);
      box-shadow: 12px 16px 22px 0px rgba(22, 25, 97, 0.33);
    }

    &.resend {
      @extend .invisibleInput !optional;
      @extend .greyText !optional;
      font-size: 10px;
      text-decoration-line: underline;
    }
  }
}